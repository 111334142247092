<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="预约日历"/>
        <div style="margin:30px auto;text-align: center">
            <el-date-picker
                size="small"
                align="center"
                v-model="form.date"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM-dd"
                placeholder="选择月" @blur="dateChange">
            </el-date-picker>
        </div>
        <div class="calendar">
            <div v-for="h in calendar.header" :key="h.ID" class="box header">{{h}}</div>
            <div v-for="(value, key, index) in calendar.data" :key="index" class="box">
                <div class="day">
                    <router-link :to="`/main/appoint?date=${key}`">{{key|getDay}}</router-link>
                </div>
                <div v-for="(v, d, j) in value" :key="j">
                    <router-link :to="`/main/appoint?date=${key}&doctor=${d}`">
                        {{d}}({{v}})
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            function getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = year + seperator1 + month + seperator1 + strDate;
                return currentdate;
            }
            return {
                form: {
                    date: getNowFormatDate(),
                },
                calendar: {
                    header: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                    data: []
                }
            }
        },
        filters: {
            getDay(date) {
                let d = date.split("-")
                return d[2]
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get('/surgery/appoint/calendar', {params: this.form})
                this.calendar.data = resp.data.data
                console.log(resp)
            },
            dateChange(e) {
                console.log(e)
                this.getData()
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
        color: #333;
    }

    .calendar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;

        .header {
            font-weight: bold;
            font-size: 110%;
            line-height: 50px;
            min-height: 50px !important;
        }

        .box {
            width: 14.28%;
            min-height: 100px;
            /*margin-bottom: 2px;*/
            border-bottom: 1px solid #ccc;

            .day {
                font-size: 150%;
            }
        }
    }
</style>
